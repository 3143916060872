import React,{useEffect} from 'react';
import { Grid, Box, Tooltip } from "@mui/material";
import { useLocation } from "react-router-dom";
//Translations
import { useTranslation } from "react-i18next";
//Social media icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import LocationOnIcon from '@mui/icons-material/LocationOn';
//Logo and icons
import logo from "../../assets/logo_footer.png";
import logo_small from "../../assets/logo_footer_small.png";


const Footer = () =>{
    const url_object = useLocation();
    const [gridDirection, setGridDirection] = React.useState("row");
    const [windowWidth, setWindowWidth] = React.useState(0);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        updateDimension();
    
        window.addEventListener("resize", updateDimension);
    
        return () => {
          window.removeEventListener("resize", updateDimension);
        };
      }, [windowWidth]);
    
      const updateDimension = () => {
        if (window.innerWidth <= 1025) {
          setGridDirection("column");
        } else {
          setGridDirection("row");
        }
        setWindowWidth(window.innerWidth);
      };

    return(
        <section className="footer">
        <Box>
          <Grid
            container
            direction={gridDirection}
            alignItems="center"
            justifyContent="space-between"
            //columnGap={2}
          >
            <Grid item>
              <a href={url_object.pathname === "/" ? "#top" : "/"}>
              {windowWidth < 400 ? (
                <img src={logo_small} alt="Logo" className="footer__logo" />
              ) : (
                <img src={logo} alt="Logo" className="footer__logo" />
              )}
              </a>
            </Grid>

            

            <Grid item>
              <div className="footer__navigation">
                <ul className="footer__list">
                  <li className="footer__item" key="ser">
                    <a href={url_object.pathname === "/" ? "#services" : "/services"} className="btn-navbar">
                      <div className="font-face-gm" style={{fontSize:"1.6rem"}}>{t("footer_szolg")}</div>
                    </a>
                  </li>

                  <li className="footer__item" key="pro">
                    <a href={url_object.pathname === "/" ? "/projects" : "/projects"} className="btn-navbar">
                      <div className="font-face-gm" style={{fontSize:"1.6rem"}}>{t("footer_munk")}</div>
                    </a>
                  </li>

                  <li className="footer__item" key="cont">
                    <a href={url_object.pathname === "/" ? "#contact" : "/#contact"} className="btn-navbar">
                      <div className="font-face-gm" style={{fontSize:"1.6rem"}}>{t("footer_kont")}</div>
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>

            <Grid item>
              <ul className="footer__logo_list" style={{paddingLeft:'10rem'}}>
                <li className="footer__item--logo" key="fb">
                  <a
                    href="https://www.facebook.com/TeszVeszGarden"
                    target="_blank"
                  >
                    <FacebookIcon className="footer__logo_icons" />
                  </a>
                </li>
                <li className="footer__item--logo" key="inst">
                  <a
                    href="https://www.instagram.com/teszveszgarden/"
                    target="_blank"
                  >
                    <InstagramIcon className="footer__logo_icons" />
                  </a>
                </li>
                <li className="footer__item--logo" key="tel">
                  <Tooltip
                    disableFocusListener
                    title={
                      <div
                        className="font-face-gm"
                        style={{
                          fontStyle: "normal",
                          fontWeight: "bold",
                          fontSize: "30px", //30
                          lineHeight: "50px",
                          letterSpacing: "0.05em",
                          color: "#FFFFFF",
                        }}
                      >
                        +40741123163
                      </div>
                    }
                  >
                    <a href="tel:+40741123163">
                      <PhoneInTalkIcon className="footer__logo_icons"  />
                    </a>
                  </Tooltip>
                </li>
                <li className="footer__item--logo" key="location">
                  <a href="https://www.google.com/maps/@46.3408271,25.381249,18.75z"  target="_blank">
                  <LocationOnIcon className="footer__logo_icons"/>
                  </a>
                </li>
              </ul>
            </Grid>
            

          </Grid>
        </Box>
      </section>
    )
}

export default Footer;