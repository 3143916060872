import axiosInstance from "../axiosApi";


const get_all_project = () =>{
    return axiosInstance.get('/tszvprojects/all_projects/');
}

const delete_project = (id) => {
    return axiosInstance.delete('/tszvprojects/get_project/'+ id);
}

const get_project = (id) =>{
    return axiosInstance.get('/tszvprojects/get_project/'+ id);
}

const edit_project = (id,data) =>{
    return axiosInstance.put('/tszvprojects/get_project/'+id,data);
}

const get_projects_for_display = () =>{
    return axiosInstance.get('/tszvprojects/all_projects_display/');
}

const get_all_projects_for_list = () =>{
    return axiosInstance.get('/tszvprojects/all_projects_list/');
}

const get_project_public = (id) =>{
    return axiosInstance.get('/tszvprojects/get_project_public/'+id);
}





export default  {
   get_all_project,
   delete_project,
   get_project,
   edit_project,
   get_projects_for_display,
   get_all_projects_for_list,
   get_project_public,
}