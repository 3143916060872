import React, { useEffect } from "react";
import { Grid, ImageList, ImageListItem } from "@mui/material";
import { useParams } from "react-router-dom";
import NavBar from "../NavBarComponent/NavBar";
import MobileNavBar from "../NavBarComponent/MobileNavBar";
import Footer from "../NavBarComponent/Footer";
import project_service from "../../services/projects.services";
//Translations
import { useTranslation } from "react-i18next";

const ProjectImages = () => {
  const object_id = useParams();
  const { t, i18n } = useTranslation();
  const [imageColumn, setImageColumn] = React.useState(4);
  const [windowWidth, setWindowWidth] = React.useState(0);
  const [projectImages, setProjectImages] = React.useState([]);
  const [projectData, setProjectData] = React.useState({});

  useEffect(() => {
    updateDimension();

    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [windowWidth]);

  const updateDimension = () => {
    if (window.innerWidth <= 600) {
      setImageColumn(1);
    } else if (window.innerWidth <= 900) {
      setImageColumn(2);
    } else {
      setImageColumn(4);
    }
    //console.log(imageColumn);
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    retriveProject(object_id.id);
  }, []);

  const retriveProject = (id) => {
    project_service
      .get_project_public(id)
      .then((response) => {
        //console.log(response.data);
        const result = response.data["project_images"];
        //console.log(result);
        //let dev = "http://localhost:8000/api/tszvprojects";
        setProjectData(response.data["project_images"][0].file_owner);

        result.map((item) => (item["file_name"] = process.env.REACT_APP_IMG_URL + item["file_name"]));
        setProjectImages(result);
        /*
            let help_cover = response.data['cover_image']
            console.log(help_cover);
            help_cover.map(image => image.file_name = 'http://localhost:8000/api/tszvprojects' + image.file_name);
            console.log(help_cover)
            */

        //setProjectImagesData(help_array);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <section className="project-images">
      {windowWidth < 900 ? (
        <>
          <MobileNavBar />
        </>
      ) : (
        <div className="landing-section__navbar">
          <NavBar />
        </div>
      )}

      <div className="u-center-text">
        <h2 className="heading-secondary heading-secondary--white">
          {i18n.language === "hu"
            ? projectData["title"]
            : projectData["title_ro"]}
          &nbsp;
          {projectData["detailsYear"]}
        </h2>
      </div>

      <Grid container padding={2}>
        <ImageList variant="masonry" cols={imageColumn} gap={16}>
          {projectImages !== undefined &&
            projectImages.map((item) => (
              <ImageListItem key={item.id}>
                <img
                  src={`${item["file_name"]}?w=248&fit=crop&auto=format`}
                  srcSet={`${item["file_name"]}?w=248&fit=crop&auto=format&dpr=3 2x`}
                  alt="kep"
                  loading="lazy"
                />
              </ImageListItem>
            ))}
        </ImageList>
      </Grid>
      <Footer/>
    </section>
  );
};

export default ProjectImages;
