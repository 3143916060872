import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import newLogo from "../../assets/newLogo.png";
import ro from "../../assets/romaniaflagicon32.png";
import hu from "../../assets/hungaryflagicon32.png";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

const MobielNavBar = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const url_object = useLocation();

  const goToHome = () => {
    navigate("/");
  };

  const handleLanguage = (value) => {
    localStorage.setItem("lang", value);
    i18n.changeLanguage(value);
  };

  return (
    <React.Fragment>
      <button
        onClick={goToHome}
        className="mobile-logo"
        style={{
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
        }}
      >
        <img className="mobile-logo__logo" src={newLogo} alt="Teszveszgarden" />
      </button>
      <div className="mobile-navigation">
        <input
          type="checkbox"
          className="mobile-navigation__checkbox"
          id="navi-toggle"
        />

        <label htmlFor="navi-toggle" className="mobile-navigation__button">
          <span className="mobile-navigation__icon">&nbsp;</span>
        </label>

        <div className="mobile-navigation__background">&nbsp;</div>

        <nav className="mobile-navigation__nav">
          <ul className="mobile-navigation__list">
            <li className="mobile-navigation__item">
              <a
                href={url_object.pathname === "/" ? "#top" : "/"}
                className="mobile-navigation__link"
              >
                {t("mobile_navbar_home")}
              </a>
            </li>
            <li className="mobile-navigation__item">
              <a
                href={url_object.pathname === "/" ? "/#services" : "/services"}
                className="mobile-navigation__link"
              >
                {t("mobile_navbar_szolg")}
              </a>
            </li>
            <li className="mobile-navigation__item">
              <a
                href={url_object.pathname === "/" ? "/projects" : "/projects"}
                className="mobile-navigation__link"
              >
                {t("mobile_navbar_munka")}
              </a>
            </li>
            <li className="mobile-navigation__item">
              <a
                href={url_object.pathname === "/" ? "#contact" : "/#contact"}
                className="mobile-navigation__link"
              >
                {t("mobile_navbar_kontakt")}
              </a>
            </li>
            <li className="mobile-navigation__item">
              <Stack
                component="div"
                direction="row"
                spacing={4}
                justifyContent="center"
              >
                <Box
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLanguage("ro")}
                  component="img"
                  src={ro}
                  alt="romanian flag icon"
                />

                <Box
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLanguage("hu")}
                  component="img"
                  src={hu}
                  alt="romanian flag icon"
                />
              </Stack>
            </li>
          </ul>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default MobielNavBar;
