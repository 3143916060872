import React, { useEffect } from "react";
import visitormessageServices from "../services/visitormessage.services";
import AdminNavbar from "./admin_navbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const ManageVisitors = () => {
  const [visitors, setVisitors] = React.useState([]);

  useEffect(() => {
    getVisitorsMessage();
  }, []);

  const getVisitorsMessage = () => {
    visitormessageServices
      .getVisitorsMessage()
      .then((response) => {
        console.log(response.data);
        setVisitors(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <React.Fragment>
      <AdminNavbar />

      <div className="u-center-text">
        <h2 className="heading-secondary" style={{ color: "black" }}>
          Messages
        </h2>
      </div>

      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        style={{ paddingTop: 50, paddingLeft: 20 }}
        columnGap={5}
        rowGap={5}
        direction="row"
      >
        {visitors !== [] &&
          visitors.map((item) => (
            <>
              <Grid item key={item.id}>
                <Card key={item.id} sx={{ maxWidth: 275 }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 20 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {item.tel}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 20 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {item.name}
                    </Typography>
                    <Typography variant="h5" component="div">
                      {item.email}
                    </Typography>
                    <br />
                    <Typography variant="h5">{item.message}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </>
          ))}
      </Grid>
    </React.Fragment>
  );
};

export default ManageVisitors;
