import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import visitor from "../assets/Admin/visitor_32.png";
import projects from "../assets/Admin/flowchart_32.png";

import { useNavigate } from "react-router-dom";

export default function TemporaryDrawer() {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const goToManageProject = () => {
    navigate("/manageProject");
  };

  const goToVisitors = () => {
    navigate("/manageVisitors");
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button onClick={goToManageProject} key={"manage_project"}>
          <ListItemIcon>
            <img src={projects} alt="projects" />
          </ListItemIcon>
          <ListItemText>Manage Projects</ListItemText>
        </ListItem>
        <Divider />
        <ListItem button key={"visitors"} onClick={goToVisitors}>
          <ListItemIcon>
            <img src={visitor} alt="visitors" />
          </ListItemIcon>
          <ListItemText>Manage Visitors</ListItemText>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(anchor, true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
