import React from "react";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import newLogo from "../../assets/newLogo.png";
import { useNavigate } from "react-router-dom";
import "../../App.scss";

import ro from "../../assets/romaniaflagicon32.png";
import hu from "../../assets/hungaryflagicon32.png";

import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import GTranslateIcon from '@mui/icons-material/GTranslate';
import LanguageIcon from '@mui/icons-material/Language';

const NavBar = () => {
  const url_object = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListValue = (value) => {
    //console.log("lang", value);
    localStorage.setItem("lang", value);
    i18n.changeLanguage(value);
    setAnchorEl(null);
  };

  const goToHome = () => {
    navigate("/");
  };

  return (
    <React.Fragment>
      <AppBar
        position="static"
        style={{
          background: "transparent",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            onClick={goToHome}
          >
            <img className="logo" src={newLogo} alt="Teszveszgarden" />
          </button>

          <Box display="flex" flexGrow={1}></Box>
          <Stack spacing={3} direction="row">
            <a
              href={url_object.pathname === "/" ? "#services" : "/services"}
              className="btn-navbar"
            >
              <div className="btn-navbar-text">
                <div className="font-face-gm">{t("navbar_szolg")}</div>
              </div>
            </a>

            <a
              href={url_object.pathname === "/" ? "/projects" : "/projects"}
              className="btn-navbar"
            >
              <div className="btn-navbar-text">
                <div className="font-face-gm">{t("navbar_munkaink")}</div>
              </div>
            </a>

            <a
              href={url_object.pathname === "/" ? "#contact" : "/#contact"}
              className="btn-navbar"
            >
              <div className="btn-navbar-text">
                <div className="font-face-gm">{t("navbar_kapcsolat")}</div>
              </div>
            </a>

            <a
              href="#"
              onClick={handleOpen}
              className="btn-navbar"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <div className="btn-navbar-text">
                <div className="font-face-gm">{t("navbar_nyelv")}&nbsp;&nbsp;&nbsp;<LanguageIcon className="language-icon"/></div>
              </div>
            </a>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              //onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  backgroundColor: "#FFFFFF",
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={() => handleListValue("ro")}>
                <Box component="img" src={ro} alt="romanian flag icon" />{" "}
                <div style={{ paddingLeft: 5 }} className="font-face-gm">
                  RO
                </div>
              </MenuItem>
              <MenuItem onClick={() => handleListValue("hu")}>
                <Box component="img" src={hu} alt="hungarian flag icon" />{" "}
                <div style={{ paddingLeft: 5 }} className="font-face-gm">
                  HU
                </div>
              </MenuItem>
            </Menu>
          </Stack>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default NavBar;
