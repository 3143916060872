import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
//Extern components
import NavBar from "../NavBarComponent/NavBar";
import MobielNavBar from "../NavBarComponent/MobileNavBar";
import Footer from "../NavBarComponent/Footer";
//Social media icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
//Logo and icons
import newLogo from "../../assets/newLogo.png";
import garden_work from "../../assets/pruners.png";
import ecology from "../../assets/ecology_2.png";
import garden from "../../assets/garden.png";
import irrigation_sys from "../../assets/irrigation-system.png";
import video from "../../assets/video.mp4";
//Translations
import { useTranslation } from "react-i18next";
//App scss
import "../../App.scss";
//Services
import project_service from "../../services/projects.services";
import visitormessageServices from "../../services/visitormessage.services";
import { Helmet } from "react-helmet";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Main = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [projectCardsInfo, setProjectCardsInfo] = React.useState([]);
  const [gridDirection, setGridDirection] = React.useState("row");
  const [windowWidth, setWindowWidth] = React.useState(0);
  const [messageData, setMessageData] = React.useState({
    name: "",
    tel: "",
    email: "",
    message: "",
  });

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setMessageData({
      name: "",
      tel: "",
      email: "",
      message: "",
    });
    setOpen(false);
  };

  useEffect(() => {
    updateDimension();

    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [windowWidth]);

  const updateDimension = () => {
    if (window.innerWidth <= 900) {
      setGridDirection("column");
    } else {
      setGridDirection("row");
    }
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    retriveProject();
  }, []);

  const retriveProject = async () => {
    try {
      const response = await project_service.get_projects_for_display();

      const result = JSON.parse(response.data);
      //result.pop();
      //console.log(result);

      //let dev = "http://localhost:8000/api/tszvprojects";
      //let prod = 'https://teszveszgarden.ro/api/tszvprojects';

      result.map((item) => (item["cover_image"] = process.env.REACT_APP_IMG_URL + item["cover_image"]));
      setProjectCardsInfo(result);
    } catch (e) {
      console.log(e);
    }
  };

  const handleText = (event) => {
    const { name, value } = event.target;
    setMessageData({ ...messageData, [name]: value });
  };

  const submitData = async () => {
    //console.log(messageData);

    try {
      const response = await visitormessageServices.postMessage(messageData);

      //console.log(response.data);
      if (response.data["message"] === "success") {
        setOpen(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const goToProjects = (id) => {
    //console.log(id);
    navigate("/projects/" + id);
  };

  return (
    <>
      <Helmet>
        <title>Teszveszgarden</title>
        <meta
          name="description"
          content="Kertrendezes, kertepites teruletgondozas"
        />
        <link rel="canonical" href="https://teszveszgarden.ro/" />
        <meta
          name="keywords"
          content="Kerteszet, kertepites, teruletgondozas, gyepnyiras, fu nyiras"
        />
      </Helmet>

      <div id="top" />
      <section className="landing-section">
        {windowWidth < 900 ? (
          <>
            <MobielNavBar />
          </>
        ) : (
          <div className="landing-section__navbar">
            <NavBar />
          </div>
        )}
        <div className="landing-section__text-box">
          <h1 className="heading-primary">
            <span className="heading-primary--main">Teszvesz</span>
            <span className="heading-primary--sub">Garden</span>
          </h1>
          <a href="#services" className="btn btn--green btn--animated">
            {t("main_page_tudj_meg_tobbet")}
          </a>
        </div>
      </section>

      <section className="services">
        <div className="bg-video">
          <video className="bg-video__content" autoPlay muted loop>
            <source src={video} type="video/mp4" />
            Your browser is not supported!
          </video>
        </div>

        <div id="services"></div>

        <div className="u-center-text u-margin-bottom-big">
          <h2 className="heading-secondary heading-secondary--white">
            {t("main_page_szolg")}
          </h2>
        </div>

        <Grid
          container
          direction={gridDirection}
          justifyContent="space-around"
          rowGap={3}
        >
          <Grid item xs={2} container
            justifyContent="center"
            alignItems="center">
            <div className="feature-box">
              <i className="feature-box__icon">
                <Box
                  component="img"
                  src={garden_work}
                  alt="Automatizált öntözőrendszerek tervezése és kivitelezése"
                />
              </i>
              <p className="heading-tertiary u-margin-bottom-small">
                {t("main_szolg_1")}
              </p>
            </div>
          </Grid>

          <Grid item xs={2} container
            justifyContent="center"
            alignItems="center">
            <div className="feature-box">
              <i className="feature-box__icon">
                <Box
                  component="img"
                  src={ecology}
                  alt="Automatizált öntözőrendszerek tervezése és kivitelezése"
                />
              </i>
              <p className="heading-tertiary u-margin-bottom-small">
                {t("main_szolg_2")}
              </p>
            </div>
          </Grid>

          <Grid item xs={2} container
            justifyContent="center"
            alignItems="center">
            <div className="feature-box">
              <i className="feature-box__icon">
                <Box
                  component="img"
                  src={garden}
                  alt="Automatizált öntözőrendszerek tervezése és kivitelezése"
                />
              </i>
              <p className="heading-tertiary u-margin-bottom-small">
                {t("main_szolg_3")}
              </p>
            </div>
          </Grid>

          <Grid item xs={2} container
            justifyContent="center"
            alignItems="center">
            <div className="feature-box">
              <i className="feature-box__icon">
                <Box
                  component="img"
                  src={irrigation_sys}
                  alt="Automatizált öntözőrendszerek tervezése és kivitelezése"
                />
              </i>
              <p className="heading-tertiary u-margin-bottom-small">
                {t("main_szolg_4")}
              </p>
            </div>
          </Grid>
        </Grid>

        <div className="btn-box">
          <div className="u-center-text">
            <a href="/services" className="btn btn--white btn--animated">
              {t("main_page_more_szolg")} &rarr;
            </a>
          </div>
        </div>
      </section>

      <section className="projects">
        <div id="projects"></div>
        <div className="u-center-text">
          <h2 className="heading-secondary heading-secondary--white">
            {t("main_page_munk")}
          </h2>
        </div>

        <Grid
          container
          direction={gridDirection}
          rowGap={5}
          columnGap={5}
          justifyContent="center"
          alignItems="center"
        >
          {projectCardsInfo !== undefined &&
            projectCardsInfo.map((item, index) => (
              <Grid item xs key={index} className="grid-item">
                <div className="card">
                  <div className="card__side card__side--front">
                    <img
                      alt="cover"
                      className="card__picture"
                      src={item["cover_image"]}
                    />
                    <h4 className="card__heading">
                      <span className="card__heading-span card__heading-span--1">
                        {i18n.language === "hu"
                          ? item["title"]
                          : item["title_ro"]}
                      </span>
                    </h4>
                    <div className="card__details">
                      <ul>
                        {i18n.language === "hu"
                          ? item["details"].map((item, index) => (
                              <li key={index}>{item}</li>
                            ))
                          : item["details_ro"].map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                      </ul>
                    </div>
                  </div>
                  <div className="card__side card__side--back card__side--back-1">
                    <div className="card__cta">
                      <div className="card__price-box">
                        <button
                          onClick={() => goToProjects(item["id"])}
                          className="btn btn--green"
                        >
                          {t("main_page_project_details")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      </section>

      <section className="contact">
        <Grid container justifyContent="center">
          <div id="contact" />
          <div className="book">
            <div className="book__form">
              <div className="form">
                <div className="u-margin-bottom-medium">
                  <h2 className="heading-secondary heading-secondary--green">
                    {t("main_page_kont")}
                  </h2>
                </div>

                <div className="form__group">
                  <input
                    onChange={handleText}
                    value={messageData.name}
                    type="text"
                    className="form__input"
                    placeholder={t("contact_name")}
                    name="name"
                    id="name"
                    required
                  />
                  <label className="form__label">{t("contact_name")}</label>
                </div>

                <div className="form__group">
                  <input
                    onChange={handleText}
                    value={messageData.tel}
                    type="text"
                    className="form__input"
                    placeholder={t("contact_tel")}
                    id="tel"
                    name="tel"
                    required
                  />
                  <label className="form__label">{t("contact_tel")}</label>
                </div>

                <div className="form__group">
                  <input
                    onChange={handleText}
                    value={messageData.email}
                    type="email"
                    className="form__input"
                    placeholder="Email"
                    id="email"
                    name="email"
                    required
                  />
                  <label className="form__label">Email</label>
                </div>

                <div className="form__group">
                  <textarea
                    onChange={handleText}
                    value={messageData.message}
                    type="text"
                    className="form__input"
                    placeholder={t("contact_message")}
                    name="message"
                    id="comment"
                    rows="3"
                    required
                  />
                  <label className="form__label">{t("contact_message")}</label>
                </div>

                <div className="form__group">
                  <button onClick={submitData} className="btn btn--green">
                    {t("contact_btn")} &rarr;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </section>
      <Footer/>
      <React.Fragment>
        <div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle style={{ fontSize: "2rem" }}>
              {t("contact_form_title")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                style={{ fontSize: "1.8rem" }}
                id="alert-dialog-slide-description"
              >
                {t("contact_form_message")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                size="large"
                color="success"
                variant="contained"
                onClick={handleClose}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    </>
  );
};

export default Main;
