import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";

import "./../App.scss";

import wateringcan from "../assets/Loading/wateringcan.svg";
import plant from "../assets/Loading/plant.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Loading = () => {
  return (
    <React.Fragment>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        //onClose={open}
        PaperProps={{
          sx: {
            width: 600,
            height: 450,
            backgroundColor: "rgba(64, 43, 25, 0.8)",
            borderRadius: 20,
          },
        }}
      >
        {/*<DialogTitle>{"Use Google's location service?"}</DialogTitle>*/}
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item style={{ paddingLeft: 130 }}>
              <img
                alt="wateringcan"
                class="wateringcan"
                width="135"
                height="135"
                src={wateringcan}
              />
            </Grid>

            <Grid item>
              <div class="drop" />
              <div class="drop2" />
              <div class="drop3" />
              <div class="drop4" />
            </Grid>

            <Grid item>
              <img alt="growingPlant" class="growing_plant" src={plant} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default Loading;
