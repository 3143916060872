import React, { useEffect } from "react";
import axiosInstance from "../axiosApi";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import "./../App.scss";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [windowWidth, setWindowWidth] = React.useState(0);

  const navigate = useNavigate();

  const [Userdata, setUserdata] = React.useState({
    username: "",
    password: "",
  });
  const [error, setError] = React.useState();

  useEffect(() => {
    updateDimension();

    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [windowWidth]);

  const handleInputChanges = (event) => {
    const { name, value } = event.target;
    setUserdata({ ...Userdata, [name]: value });
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await axiosInstance.post(
        "/authentication/token/obtain/",
        { username: Userdata.username, password: Userdata.password }
      );

      if (response.status === 200) {
        let base64User = response.data.access.split(".")[1];
        base64User = JSON.parse(window.atob(base64User));
        localStorage.setItem("currentUser_email", base64User.email);
        localStorage.setItem("currentUser", base64User.user);
        navigate("/manageMain"); // Push home
      }
    } catch (error) {
      setError(error.response.data.detail);
    }
  };

  const updateDimension = () => {
    setWindowWidth(window.innerWidth);
  };

  return (
    <React.Fragment>
      <section className="login">
        <div className="login__text-box">
          <h1 className="heading-primary">
            <span className="heading-primary--main">Teszvesz</span>
          </h1>
        </div>

        <div className="login__form">
          <div className="login__form-group">
            <LockOutlinedIcon fontSize="large" />
          </div>

          <div className="login__form-group">
            <input
              onChange={handleInputChanges}
              value={Userdata.username}
              type="text"
              className="login__form-input"
              placeholder="Username"
              name="username"
              id="username"
              required
            />
            <label className="login__form-label">Username</label>
          </div>

          <div className="login__form-group">
            <input
              onChange={handleInputChanges}
              value={Userdata.password}
              type="password"
              className="login__form-input"
              placeholder="Password"
              name="password"
              id="password"
              required
            />
            <label className="login__form-label">Password</label>
          </div>

          <div className="login__form-group">
            <button onClick={handleLogin} className="btn btn--green">
              Login &rarr;
            </button>
            {error && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Login;
