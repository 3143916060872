import React from "react";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "./admin_navbar";

import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";

import visitors from "../assets/Admin/visitor.png";
import projects from "../assets/Admin/flowchart.png";

const ManageMain = () => {
  const navigate = useNavigate();

  const handleProjects = () => {
    navigate("/manageProject");
  };

  const handleVisitors = () => {
    navigate("/manageVisitors");
  };

  return (
    <React.Fragment>
      <AdminNavbar />

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={5}
        style={{ padding: 100 }}
      >
        <Grid item>
          <Card>
            <CardMedia>
              <img src={projects} alt="project" />
            </CardMedia>

            <CardActions>
              <Grid container justifyContent="center" alignItems="center">
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={handleProjects}
                >
                  Projects
                </Button>
              </Grid>
            </CardActions>
          </Card>
        </Grid>

        <Grid item>
          <Card>
            <CardMedia>
              <img src={visitors} alt="visitors" />
            </CardMedia>

            <CardActions>
              <Grid container justifyContent="center" alignItems="center">
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={handleVisitors}
                >
                  Visitors
                </Button>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ManageMain;
