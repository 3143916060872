//routing
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import history from "./components/history";
import PrivateRoute from "./private_route";
//Manage
import Login from "./admin/login";
import Manage from "./admin/manage";
import AddProject from "./admin/add_project";
import EditProject from "./admin/edit_project";
import ManageMain from "./admin/ManageMain";
import ManageVisitors from "./admin/manageVisitors";
//import Loading from "./components/Loading";

//Version 2
import Main from "./components/MainComponent/Main";
import Projects from "./components/ProjectsComponent/Projects";
import ProjectImages from "./components/ProjectsComponent/ProjectImages";
import Services from "./components/ServiceComponents/Services";

function App() {
  return (
    <BrowserRouter>
      <Routes history={history}>
        <Route path={"/"} element={<Main />} />
        <Route path={"/projects"} element={<Projects />} />
        <Route path={"/projects/:id"} element={<ProjectImages />} />
        <Route path={"/services"} element={<Services />} />
        <Route
          exact
          path={"/manageMain"}
          element={
            <PrivateRoute>
              <ManageMain />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={"/manageProject"}
          element={
            <PrivateRoute>
              <Manage />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={"/manageVisitors"}
          element={
            <PrivateRoute>
              <ManageVisitors />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={"/addProject"}
          element={
            <PrivateRoute>
              <AddProject />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={"/editProject/:id"}
          element={
            <PrivateRoute>
              <EditProject />
            </PrivateRoute>
          }
        />

        <Route exact path={"/login"} element={<Login />} />

        {/*<Route exact path={"/loading"} element={<Loading/>}/>*/}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
