import React, { useEffect } from "react";
import { Grid, Box, Stack } from "@mui/material";
import NavBar from "../NavBarComponent/NavBar";
import MobileNavbar from "../NavBarComponent/MobileNavBar";
import Footer from "../NavBarComponent/Footer";
import garden_work from "../../assets/pruners.png";
import ecology from "../../assets/ecology_2.png";
import garden from "../../assets/garden.png";
import irrigation_sys from "../../assets/irrigation-system.png";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Services = () => {
  const [windowWidth, setWindowWidth] = React.useState(0);
  const [gridDirection, setGridDirection] = React.useState("row");
  const { t, i18n } = useTranslation();
  

  useEffect(() => {
    updateDimension();

    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [windowWidth]);

  const updateDimension = () => {
    if (window.innerWidth <= 900) {
      setGridDirection("column");
    } else {
      setGridDirection("row");
    }
    setWindowWidth(window.innerWidth);
  };
  return (
    <>
      <Helmet>
        <title>Teszveszgarden</title>
        <meta name="description" content="Szolgaltatasink" />
        <link rel="canonical" href="https://teszveszgarden.ro/services" />
        <meta
          name="keywords"
          content="Kerteszet, kertepites, teruletgondozas, gyepnyiras, fu nyiras, Marefalva, Szekelyudvarhely"
        />
      </Helmet>

      <section className="services-more">
        {windowWidth < 900 ? (
          <>
            <MobileNavbar />
          </>
        ) : (
          <div className="landing-section__navbar">
            <NavBar />
          </div>
        )}

        <div className="u-center-text u-margin-bottom-small">
          <h2 className="heading-secondary heading-secondary--white">
            {t("service_title")}
          </h2>
        </div>

        <Grid
          container
          direction={gridDirection}
          spacing={3}
        >
          <Grid item xs={6} container
            justifyContent="center"
            alignItems="center">
            <div className="services-feature-box">
              <i className="services-feature-box__icon">
                <Stack direction="row" justifyContent="center" spacing={5}>
                  <Box
                    component="img"
                    src={garden_work}
                    alt="Automatizált öntözőrendszerek tervezése és kivitelezése"
                  />

                  <Box
                    component="img"
                    src={ecology}
                    alt="Automatizált öntözőrendszerek tervezése és kivitelezése"
                  />
                </Stack>
              </i>
              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_1")}
              </p>
              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_2")}
              </p>

              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_3")}
              </p>

              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_4")}
              </p>

              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_5")}
              </p>
              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_6")}
              </p>

              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_7")}
              </p>

              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_8")}
              </p>
            </div>
          </Grid>

          <Grid item xs={6} container
            justifyContent="center"
            alignItems="center">
            <div className="services-feature-box">
              <i className="services-feature-box__icon">
                <Stack direction="row" justifyContent="center" spacing={5}>
                  <Box
                    component="img"
                    src={garden}
                    alt="Automatizált öntözőrendszerek tervezése és kivitelezése"
                  />

                  <Box
                    component="img"
                    src={irrigation_sys}
                    alt="Automatizált öntözőrendszerek tervezése és kivitelezése"
                  />
                </Stack>
              </i>

              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_9")}
              </p>

              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_10")}
              </p>

              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_11")}
              </p>

              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_12")}
              </p>

              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_13")}
              </p>

              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_14")}
              </p>

              <p className="heading-tertiary u-margin-bottom-small">
                {t("service_15")}
              </p>
            </div>
          </Grid>
        </Grid>
        <br/>
        <br/>
        <br/>
        <Footer style={{padding:"4rem"}}/>
       
      </section>
    </>
  );
};

export default Services;
