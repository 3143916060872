import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import useLoggedIn from "./user.component";

function isLogin(user = null) {
  //if(user){
  //  return false;
  //}
  if (localStorage.getItem("currentUser") === "null") {
    return false;
  }
  return true;
}

const PrivateRoute = ({ children }) => {
  const { status, data } = useLoggedIn("/authentication/user/current/");

  useEffect(() => {
    console.log("Sefule", status, data);
    //if(data){
    // console.log(data);
    //console.log(status);
    // }

    //console.log(isLogin());
  }, [data]);

  return isLogin === false ? <Navigate to="/login" /> : children;
};

export default PrivateRoute;
