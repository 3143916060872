import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import translationRO from './languages/ro/translationRO.json';
import translationHU from './languages/hu/translationHU.json';


const resources = {

    hu:{
        translation:translationHU
    },

    ro:{
        translation:translationRO
    }
};

const lang = localStorage.getItem('lang');

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lang !== null ? lang:'hu',

        interpolation:{
            escapeValue:false
        }
    });

export default i18n;