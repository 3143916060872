import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import AdminNavbar from "./admin_navbar";
import { Grid, Typography, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import project_service from "../services/projects.services";

const useStyles = makeStyles({
  tableHead: {
    backgroundColor: "#293847",
    color: "white",
    fontSize: 20,
  },

  head_text: {
    color: "white",
  },
});

const Manage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [projects, setProjects] = React.useState([]);

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {}, [projects]);

  const getProjects = () => {
    project_service
      .get_all_project()
      .then((response) => {
        console.log(response.data);
        setProjects(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const goToAddProject = () => {
    navigate("/addProject");
  };

  const editProject = (id) => {
    navigate("/editProject/" + id);
  };

  const deleteProject = (id) => {
    let all_project = projects;

    console.log("Delte id ", id);

    project_service
      .delete_project(id)
      .then((response) => {
        console.log(response.data);

        if (response.data["data"] === "success") {
          setProjects(all_project.filter((i) => i.id !== id));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <React.Fragment>
      <AdminNavbar />

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        style={{ padding: 50 }}
      >
        <Grid item xs>
          <Button onClick={goToAddProject} variant="contained">
            <Typography>Add project</Typography>
            <AddCircleIcon fontSize="large" />
          </Button>
        </Grid>

        <Grid item xs>
          <TableContainer component={Paper} sx={{ boxShadow: 5 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>
                    {" "}
                    <Typography variant="h6" className={classes.head_text}>
                      {" "}
                      Id{" "}
                    </Typography>{" "}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Typography variant="h6" className={classes.head_text}>
                      {" "}
                      Title{" "}
                    </Typography>{" "}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Typography variant="h6" className={classes.head_text}>
                      {" "}
                      Edit{" "}
                    </Typography>{" "}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Typography variant="h6" className={classes.head_text}>
                      {" "}
                      Delete{" "}
                    </Typography>{" "}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.length !== 0 &&
                  projects.map((project) => (
                    <TableRow
                      key={project.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Typography variant="h6">{project.id}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography variant="h6">{project.title}</Typography>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Button
                          variant="contained"
                          onClick={() => editProject(project.id)}
                        >
                          {" "}
                          <EditIcon fontSize="large" />{" "}
                        </Button>{" "}
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => deleteProject(project.id)}
                        >
                          {" "}
                          <DeleteIcon fontSize="large" />{" "}
                        </Button>{" "}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Manage;
