import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";

import axiosInstance from "../axiosApi";
import { useNavigate } from "react-router-dom";

import DrawerMenu from "./DrawerMenu";

const AdminNavbar = () => {
  const navigate = useNavigate();

  const LogOut = async () => {
    try {
      const response = await axiosInstance.post(
        "/authentication/token/delete/",
        { withCredentials: true }
      );
      if (response.status === 200) {
        localStorage.setItem("currentUser", null);
        localStorage.setItem("currentUserGroups", null);
        localStorage.setItem("currentUserEntity", null);
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <DrawerMenu />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Admin Panel
            </Typography>
            <Button color="secondary" variant="contained" onClick={LogOut}>
              <LogoutIcon /> LogOut
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
    </React.Fragment>
  );
};

export default AdminNavbar;
