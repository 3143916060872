import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";

import AdminNavbar from "./admin_navbar";

import { Grid, Typography, Button, Paper, TextField } from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import { v4 as uuidv4 } from "uuid";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";

import axiosInstance from "../axiosApi";
import Cookies from "js-cookie";

import Loading from "../components/Loading";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "white",
    width: 700,
    borderRadius: 20,
    borderBlockColor: "black",
  },

  textField: {
    width: "30ch",
    fontSize: 20,
    "& .MuiInputLabel-formControl": {
      color: "black",
    },
  },

  textFieldDetails: {
    width: "23ch",
    fontSize: 20,
    "& .MuiInputLabel-formControl": {
      color: "black",
    },
  },
});

const AddProject = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [uploaded_image, setUploaded_image] = React.useState([]);
  const [upload_cover_image, setUploadCoverImage] = React.useState([]);
  const [preview_image, setPreview_image] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [projectData, setProjectData] = React.useState({
    title: "",
    title_ro: "",
    detailsYear: "",
    project_type: "",
  });

  const [projcetDetails, setProjectDetails] = React.useState([
    {
      id: uuidv4(),
      description: "",
      description_ro: "",
    },
  ]);

  useEffect(() => {
    //console.log(projcetDetails);
    //console.log("Cover image", upload_cover_image);
  }, [projcetDetails, upload_cover_image]);

  const TITEL_LIMIT = 20;
  const DETAILT_YEAR_LIMIT = 4;

  const handleData = (event) => {
    const { name, value } = event.target;
    setProjectData({ ...projectData, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    console.log(projectData);
    console.log(uploaded_image);

    let details = "";
    let details_ro = "";

    projcetDetails.map((data) => (details = details + data.description + ";"));
    projcetDetails.map(
      (data) => (details_ro = details_ro + data.description_ro + ";")
    );
    console.log(details);
    console.log(details_ro);

    let form_data = new FormData();
    form_data.append("title", projectData.title);
    form_data.append("title_ro", projectData.title_ro);
    form_data.append("detailsYear", projectData.detailsYear);
    form_data.append("project_type", projectData.project_type);

    for (let i = 0; i < uploaded_image.length; i++) {
      form_data.append(`files[${i}]`, uploaded_image[i]);
    }

    for (let i = 0; i < upload_cover_image.length; i++) {
      form_data.append(`cover_photo[${i}]`, upload_cover_image[i]);
    }

    form_data.append("details", details);
    form_data.append("details_ro", details_ro);

    console.log("DATA", form_data);

    try {
      const response = await axiosInstance.post(
        "/tszvprojects/save_project/",
        form_data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFTOKEN": Cookies.get("csrftoken"),
          },
        }
      );

      console.log("RESPONSE ", response.data);

      if (response.data["message"] === "success") {
        setLoading(false);
        navigate("/manageMain");
      }
    } catch (error) {
      setLoading(false);
      console.log(error.response.data.detail);
    }
  };

  const handleChangeDetails = (id, event) => {
    const newInputFields = projcetDetails.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setProjectDetails(newInputFields);
  };

  const handleAddFields = () => {
    setProjectDetails([
      ...projcetDetails,
      { id: uuidv4(), description: "", description_ro: "" },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...projcetDetails];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setProjectDetails(values);
  };

  const handleImages = (event) => {
    setUploaded_image(event.target.files);
  };

  const handleCoverImages = (event) => {
    setUploadCoverImage(event.target.files);
    console.log(event.target.files);
    const urlobj = URL.createObjectURL(event.target.files[0]);
    setPreview_image(urlobj);
  };

  return (
    <React.Fragment>
      <AdminNavbar />

      {loading === true ? (
        <Loading />
      ) : (
        <React.Fragment>
          <div className="centering">
            <Grid
              container
              //justifyContent="center"
              //alignItems="center"
              rowGap={1}
              direction="row"
              style={{ paddingTop: "5rem" }}
            >
              <Grid item xs>
                <Paper elevation={6} className={classes.paper}>
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    onError={(errors) => console.log(errors)}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs>
                        <br />
                        <Typography variant="h4">Create a project</Typography>
                      </Grid>

                      <Grid item xs>
                        <Typography variant="h6">Card details</Typography>
                      </Grid>

                      <Grid item xs>
                        <TextValidator
                          className={classes.textField}
                          autoFocus
                          margin="dense"
                          name="title"
                          value={projectData.title}
                          onChange={handleData}
                          label="Title *"
                          type="text"
                          placeholder="Title"
                          inputProps={{ maxLength: 20 }}
                          helperText={`${projectData.title.length}/${TITEL_LIMIT}`}
                          validators={["required"]}
                          errorMessages={["Required"]}
                        />
                      </Grid>

                      <Grid item xs>
                        <TextValidator
                          className={classes.textField}
                          autoFocus
                          margin="dense"
                          name="title_ro"
                          value={projectData.title_ro}
                          onChange={handleData}
                          label="Title RO*"
                          type="text"
                          placeholder="Title RO"
                          inputProps={{ maxLength: 20 }}
                          helperText={`${projectData.title_ro.length}/${TITEL_LIMIT}`}
                          validators={["required"]}
                          errorMessages={["Required"]}
                        />
                      </Grid>

                      <Grid item xs>
                        <TextValidator
                          className={classes.textField}
                          autoFocus
                          margin="dense"
                          name="detailsYear"
                          value={projectData.detailsYear}
                          onChange={handleData}
                          label="Year *"
                          type="text"
                          placeholder="Year"
                          inputProps={{ maxLength: 4 }}
                          helperText={`${projectData.detailsYear.length}/${DETAILT_YEAR_LIMIT}`}
                          validators={["required"]}
                          errorMessages={["Required"]}
                        />
                      </Grid>

                      <Grid item xs>
                        <RadioGroup
                          required
                          name="project_type"
                          value={projectData.project_type}
                          onChange={handleData}
                        >
                          <FormControlLabel
                            value="mainPage"
                            control={<Radio />}
                            label="Main page content"
                          />
                          <FormControlLabel
                            value="nonMainPage"
                            control={<Radio />}
                            label="Not main page content"
                          />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs>
                        <TableContainer>
                          <Table
                            className={classes.table}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Cover Image</TableCell>
                                <TableCell>File names</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  rowSpan={upload_cover_image.length + 1}
                                >
                                  <label>
                                    <input
                                      style={{ display: "none" }}
                                      id="file"
                                      name="file"
                                      type="file"
                                      //multiple
                                      onChange={handleCoverImages}
                                      required
                                    />

                                    <Fab
                                      color="primary"
                                      size="small"
                                      component="span"
                                      aria-label="add"
                                      variant="extended"
                                    >
                                      <AddIcon /> Add images
                                    </Fab>
                                  </label>
                                </TableCell>
                              </TableRow>
                              {upload_cover_image.length !== 0 &&
                                Array.from(upload_cover_image).map(
                                  (file, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{file.name}</TableCell>
                                    </TableRow>
                                  )
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>

                      <Grid item xs>
                        <br />
                      </Grid>

                      <Grid item xs>
                        <Typography variant="h6">Project details</Typography>
                      </Grid>

                      {projcetDetails.map((projcetDetail) => (
                        <Grid item key={projcetDetail.id}>
                          <TextField
                            className={classes.textFieldDetails}
                            name="description"
                            label="Project Details HU"
                            //variant="filled"
                            value={projcetDetail.description}
                            onChange={(event) =>
                              handleChangeDetails(projcetDetail.id, event)
                            }
                          />

                          <TextField
                            className={classes.textFieldDetails}
                            name="description_ro"
                            label="Project Details RO"
                            //variant="filled"
                            value={projcetDetail.description_ro}
                            onChange={(event) =>
                              handleChangeDetails(projcetDetail.id, event)
                            }
                          />

                          <IconButton
                            disabled={projcetDetails.length === 1}
                            onClick={() => handleRemoveFields(projcetDetail.id)}
                          >
                            <RemoveIcon />
                          </IconButton>

                          <IconButton onClick={handleAddFields}>
                            <AddIcon />
                          </IconButton>
                        </Grid>
                      ))}

                      <Grid item xs>
                        <TableContainer>
                          <Table
                            className={classes.table}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Project Images</TableCell>
                                <TableCell>File names</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell rowSpan={uploaded_image.length + 1}>
                                  <label>
                                    <input
                                      style={{ display: "none" }}
                                      id="file"
                                      name="file"
                                      type="file"
                                      multiple
                                      onChange={handleImages}
                                      required
                                    />

                                    <Fab
                                      color="primary"
                                      size="small"
                                      component="span"
                                      aria-label="add"
                                      variant="extended"
                                    >
                                      <AddIcon /> Add images
                                    </Fab>
                                  </label>
                                </TableCell>
                              </TableRow>
                              {uploaded_image.length !== 0 &&
                                Array.from(uploaded_image).map(
                                  (file, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{file.name}</TableCell>
                                    </TableRow>
                                  )
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>

                      <Grid item xs>
                        <Button variant="contained" type="submit">
                          Submit
                        </Button>
                      </Grid>

                      <Grid item xs></Grid>
                    </Grid>
                  </ValidatorForm>
                </Paper>
              </Grid>

              <Grid item xs justifyContent="center">
                <Grid container direction="column" spacing={3}>
                  <Grid item xs>
                    <Typography variant="h4">Preview</Typography>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{ paddingLeft: "23rem", width: "70rem" }}
                  >
                    <div className="card">
                      <div className="card__side card__side--front">
                        <img
                          alt="preview"
                          className="card__picture"
                          src={preview_image !== undefined && preview_image}
                        />
                        <h4 className="card__heading">
                          <span className="card__heading-span card__heading-span--1">
                            {projectData.title !== ""
                              ? projectData.title
                              : "Title"}
                          </span>
                        </h4>
                        <div className="card__details">
                          <ul>
                            {projcetDetails[0].description === "" ? (
                              <li>Description line</li>
                            ) : (
                              projcetDetails.map((item) => (
                                <li>{item.description}</li>
                              ))
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="card__side card__side--back card__side--back-1">
                        <div className="card__cta">
                          <div className="card__price-box">
                            <button className="btn btn--green">Több</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default AddProject;
