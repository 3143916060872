import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AdminNavbar from "./admin_navbar";
import { makeStyles } from "@mui/styles";

import project_service from "../services/projects.services";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Grid, Typography, Button, Paper, TextField } from "@mui/material";

//image list
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import DeleteIcon from "@mui/icons-material/Delete";
import Cookies from "js-cookie";
import axiosInstance from "../axiosApi";

import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { v4 as uuidv4 } from "uuid";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";

import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "white",
    width: 700,
    borderRadius: 50,
    borderBlockColor: "black",
  },

  textField: {
    width: "30ch",
    fontSize: 20,
    "& .MuiInputLabel-formControl": {
      color: "black",
    },
  },

  textFieldDetails: {
    width: "23ch",
    fontSize: 20,
    "& .MuiInputLabel-formControl": {
      color: "black",
    },
  },
});

const EditProject = (props) => {
  const object_id = useParams();
  const classes = useStyles();

  const [projectData, setProjectData] = React.useState({});
  const [itemData, setItemData] = React.useState({});
  const [cover_photo, setCoverPhoto] = React.useState([]);
  const [uploaded_image, setUploaded_image] = React.useState([]);
  const [uploaded_cover_image, setUploadCoverImage] = React.useState([]);

  const [projcetDetails, setProjectDetails] = React.useState([
    {
      id: uuidv4(),
      description: "",
      description_ro: "",
    },
  ]);

  const navigate = useNavigate();

  const TITEL_LIMIT = 20;
  const DETAILS_YEAR_LIMIT = 4;

  useEffect(() => {
    retriveProject(object_id.id);
  }, []);

  useEffect(() => {}, [projectData, itemData, cover_photo]);

  const retriveProject = (id) => {
    project_service
      .get_project(id)
      .then((response) => {
        console.log(response.data);
        setProjectData(response.data["project"]);

        let help_array = response.data["project_images"];
        help_array.map(
          (image) => (image.file_name = process.env.REACT_APP_IMG_URL + image.file_name)
        );
        console.log(help_array);

        let help_cover = response.data["cover_image"];
        console.log(help_cover);
        help_cover.map(
          (image) => (image.file_name = process.env.REACT_APP_IMG_URL + image.file_name)
          //process.env.REACT_APP_IMG_URL + image.file_name)
        );
        console.log(help_cover);

        //HU
        let details = response.data["project"].details;
        let details_array = details.split(";");
        console.log(details_array);

        //RO
        let details_ro = response.data["project"].details_ro;
        let details_ro_array = details_ro.split(";");
        console.log(details_ro_array);

        let project_details = [];

        details_array.map((item) => {
          console.log("item", item);
          let help = { id: uuidv4(), description: item, description_ro: "" };

          if (help.description !== "") {
            project_details.push(help);
          }
        });

        project_details.map((item, index) => {
          console.log("item check", item);
          console.log("index", index);
          item.description_ro = details_ro_array[index];
          //details_ro_array.map(ro_item =>{
          //  console.log("item check", item);
          //  item.description_ro = ro_item;
          //})
        });

        console.log("project", project_details);
        setProjectDetails(project_details);

        setCoverPhoto(help_cover);
        setItemData(help_array);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const edit_data = () => {
    let details = "";
    let details_ro = "";

    projcetDetails.map((data) => (details = details + data.description + ";"));
    projcetDetails.map(
      (data) => (details_ro = details_ro + data.description_ro + ";")
    );
    console.log(details);
    console.log(details_ro);

    let form_data = new FormData();
    form_data.append("title", projectData.title);
    form_data.append("title_ro", projectData.title_ro);
    form_data.append("detailsYear", projectData.detailsYear);
    form_data.append("project_type", projectData.project_type);

    for (let i = 0; i < uploaded_image.length; i++) {
      form_data.append(`files[${i}]`, uploaded_image[i]);
    }

    for (let i = 0; i < uploaded_cover_image.length; i++) {
      form_data.append(`cover_photo[${i}]`, uploaded_cover_image[i]);
    }

    form_data.append("details", details);
    form_data.append("details_ro", details_ro);

    project_service
      .edit_project(object_id.id, form_data)
      .then((response) => {
        if (response.data["message"] === "success") {
          navigate("/manageMain");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {}, [itemData, cover_photo]);

  const removeCoverFile = () => {
    setCoverPhoto([]);
  };

  const removeFile = (file) => {
    console.log(file);
    let array = itemData;
    let final_array = array.filter((item) => item.id !== file.id);
    console.log(final_array);
    setItemData(final_array);
  };

  const handleImages = (event) => {
    setUploaded_image(event.target.files);
  };

  const handleCoverImage = (event) => {
    setUploadCoverImage(event.target.files);
  };

  const deleteCoverPic = (file) => {
    console.log(file);
    let file_name = file.file_name.split(process.env.REACT_APP_IMG_URL + "/files/CoverImages/"
    )[1];
    console.log(file_name);

    try {
      axiosInstance({
        url: "/tszvprojects/delete_cover/" + object_id.id, //your url
        method: "DELETE",
        withCredentials: true,
        headers: {
          "X-CSRFTOKEN": Cookies.get("csrftoken"),
          //'IF-MATCH': it_etag,
        },
        credentials: "include",
        params: {
          file: file_name,
        },
      }).then((response) => {
        console.log(response.data);
        if (response.data["message"] === "success") {
          removeCoverFile();
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const deletePic = (file) => {
    console.log(file);
    let file_name = file.file_name.split(
      process.env.REACT_APP_IMG_URL + "/files/Images/"
    )[1];
    console.log(file_name);

    try {
      axiosInstance({
        url: "/tszvprojects/project_files/" + object_id.id, //your url
        method: "DELETE",
        withCredentials: true,
        headers: {
          "X-CSRFTOKEN": Cookies.get("csrftoken"),
          //'IF-MATCH': it_etag,
        },
        credentials: "include",
        params: {
          file: file_name,
        },
      }).then((response) => {
        console.log(response.data);
        if (response.data["message"] === "success") {
          removeFile(file);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleData = (event) => {
    const { name, value } = event.target;
    setProjectData({ ...projectData, [name]: value });
  };

  const handleChangeDetails = (id, event) => {
    const newInputFields = projcetDetails.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setProjectDetails(newInputFields);
  };

  const handleAddFields = () => {
    setProjectDetails([
      ...projcetDetails,
      { id: uuidv4(), description: "", description_ro: "" },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...projcetDetails];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setProjectDetails(values);
  };

  return (
    <React.Fragment>
      <AdminNavbar />

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ padding: 50 }}
        spacing={3}
      >
        {projectData.title !== undefined && (
          <Grid item>
            <Paper elevation={6} className={classes.paper}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs>
                  <br />
                  <Typography variant="h6">Edit project</Typography>
                </Grid>

                <Grid item xs>
                  <TextField
                    className={classes.textField}
                    autoFocus
                    margin="dense"
                    name="title"
                    value={projectData.title || ""}
                    onChange={handleData}
                    label="Title *"
                    type="text"
                    placeholder="Title"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ maxLength: 20 }}
                    helperText={`${
                      projectData.title.length || 0
                    }/${TITEL_LIMIT}`}
                  />
                </Grid>

                <Grid item xs>
                  <TextField
                    className={classes.textField}
                    autoFocus
                    margin="dense"
                    name="title_ro"
                    value={projectData.title_ro || ""}
                    onChange={handleData}
                    label="Title RO*"
                    type="text"
                    placeholder="Title RO"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ maxLength: 20 }}
                    helperText={`${
                      projectData.title_ro.length || 0
                    }/${TITEL_LIMIT}`}
                  />
                </Grid>

                <Grid item xs>
                  <TextField
                    className={classes.textField}
                    autoFocus
                    margin="dense"
                    name="detailsYear"
                    value={projectData.detailsYear || ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleData}
                    label="Year *"
                    type="text"
                    placeholder="Year"
                    inputProps={{ maxLength: 4 }}
                    helperText={`${projectData.detailsYear.length}/${DETAILS_YEAR_LIMIT}`}
                    //validators={["required"]}
                    //errorMessages={["Required"]}
                  />
                </Grid>

                <Grid item xs>
                  <RadioGroup
                    required
                    name="project_type"
                    value={projectData.project_type}
                    onChange={handleData}
                  >
                    <FormControlLabel
                      value="mainPage"
                      control={<Radio />}
                      label="Main page content"
                    />
                    <FormControlLabel
                      value="nonMainPage"
                      control={<Radio />}
                      label="Not main page content"
                    />
                  </RadioGroup>
                </Grid>

                <Grid item xs>
                  <TableContainer>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Cover Image</TableCell>
                          <TableCell>File names</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell rowSpan={uploaded_cover_image.length + 1}>
                            <label>
                              <input
                                style={{ display: "none" }}
                                id="file"
                                name="file"
                                type="file"
                                //multiple
                                onChange={handleCoverImage}
                              />

                              <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"
                              >
                                <AddIcon /> Add images
                              </Fab>
                            </label>
                          </TableCell>
                        </TableRow>
                        {uploaded_cover_image.length !== 0 &&
                          Array.from(uploaded_cover_image).map(
                            (file, index) => (
                              <TableRow key={index}>
                                <TableCell>{file.name}</TableCell>
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs>
                  <Typography variant="h6">Project details</Typography>
                </Grid>

                {projcetDetails.map((projcetDetail) => (
                  <Grid item key={projcetDetail.id}>
                    <TextField
                      className={classes.textFieldDetails}
                      name="description"
                      label="Project Detatils"
                      //variant="filled"
                      value={projcetDetail.description}
                      onChange={(event) =>
                        handleChangeDetails(projcetDetail.id, event)
                      }
                    />

                    <TextField
                      className={classes.textFieldDetails}
                      name="description_ro"
                      label="Project Details RO"
                      //variant="filled"
                      value={projcetDetail.description_ro}
                      onChange={(event) =>
                        handleChangeDetails(projcetDetail.id, event)
                      }
                    />

                    <IconButton
                      disabled={projcetDetails.length === 1}
                      onClick={() => handleRemoveFields(projcetDetail.id)}
                    >
                      <RemoveIcon />
                    </IconButton>

                    <IconButton onClick={handleAddFields}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                ))}

                <Grid item xs>
                  <TableContainer>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Project Images</TableCell>
                          <TableCell>File names</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell rowSpan={uploaded_image.length + 1}>
                            <label>
                              <input
                                style={{ display: "none" }}
                                id="file"
                                name="file"
                                type="file"
                                multiple
                                onChange={handleImages}
                              />

                              <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"
                              >
                                <AddIcon /> Add images
                              </Fab>
                            </label>
                          </TableCell>
                        </TableRow>
                        {uploaded_image.length !== 0 &&
                          Array.from(uploaded_image).map((file, index) => (
                            <TableRow key={index}>
                              <TableCell>{file.name}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ minWidth: 200 }}
                    onClick={() => edit_data()}
                  >
                    SAVE
                  </Button>
                </Grid>

                <Grid item xs></Grid>
              </Grid>
            </Paper>
          </Grid>
        )}

        <Grid item>
          <Grid container direction="row" columnGap={5}>
            <Grid item>
              {cover_photo.length === 0 ? (
                <>
                  <Paper elevation={6} className={classes.paper} align="center">
                    <Typography>No images</Typography>
                  </Paper>
                </>
              ) : (
                <>
                  <Typography variant="h5">Project cover image</Typography>
                  <Paper elevation={6} className={classes.paper} align="center">
                    <ImageList sx={{ width: 700, height: 700 }} cols={1}>
                      {cover_photo.map((item) => (
                        <ImageListItem key={item.id}>
                          <img
                            src={`${item.file_name}?w=164&h=164&fit=crop&auto=format`}
                            srcSet={`${item.file_name}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            alt="edit"
                            loading="lazy"
                          />

                          <ImageListItemBar
                            title={
                              item.file_name.split("/files/CoverImages/")[1]
                            }
                            subtitle={
                              <span>
                                <React.Fragment>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => deleteCoverPic(item)}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </React.Fragment>
                              </span>
                            }
                            position="below"
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Paper>
                </>
              )}
            </Grid>

            <Grid item>
              {itemData.length === 0 ? (
                <>
                  <Paper elevation={6} className={classes.paper} align="center">
                    <Typography>No images</Typography>
                  </Paper>
                </>
              ) : (
                <>
                  <Typography variant="h5">Project images</Typography>
                  <Paper elevation={6} className={classes.paper} align="center">
                    <ImageList sx={{ width: 700, height: 700 }}>
                      {itemData !== undefined &&
                        Array.from(itemData).map((item) => (
                          <ImageListItem key={item.id}>
                            <img
                              src={`${item.file_name}?w=248&fit=crop&auto=format`}
                              srcSet={`${item.file_name}?w=248&fit=crop&auto=format&dpr=2 2x`}
                              alt="edit"
                              loading="lazy"
                            />

                            <ImageListItemBar
                              title={item.file_name.split("/files/Images/")[1]}
                              subtitle={
                                <span>
                                  <React.Fragment>
                                    <Button
                                      variant="contained"
                                      color="error"
                                      onClick={() => deletePic(item)}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </React.Fragment>
                                </span>
                              }
                              position="below"
                            />
                          </ImageListItem>
                        ))}
                    </ImageList>
                  </Paper>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EditProject;
