import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
//Extern components
import NavBar from "../NavBarComponent/NavBar";
import MobielNavBar from "../NavBarComponent/MobileNavBar";
import Footer from "../NavBarComponent/Footer";
//Translations
import { useTranslation } from "react-i18next";
//Services
import project_service from "../../services/projects.services";
import { Helmet } from "react-helmet";

const Projects = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [gridDirection, setGridDirection] = React.useState("row");
  const [windowWidth, setWindowWidth] = React.useState(0);

  const [projects, setProjects] = React.useState([]);

  useEffect(() => {
    updateDimension();

    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [windowWidth]);

  const updateDimension = () => {
    if (window.innerWidth <= 900) {
      setGridDirection("column");
    } else {
      setGridDirection("row");
    }
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    retriveProject();
  }, []);

  const retriveProject = async () => {
    try {
      const response = await project_service.get_all_projects_for_list();
      ///.then( response =>{

      const result = JSON.parse(response.data);
      //console.log(result);

      //let dev = "http://localhost:8000/api/tszvprojects";
      //let prod = 'https://teszveszgarden.ro/api/tszvprojects';

      //result.map(item => item['project_details'].file_name = process.env.REACT_APP_IMG_URL + item['project_details'].file_name);
      //setProjects(result);
      // setProjectData(response.data['project']);

      //let help_array = response.data['project_images']
      //help_array.map(image => image.file_name = 'http://localhost:8000/api/tszvprojects' + image.file_name);
      //console.log(help_array);
      result.map((item) => (item["cover_image"] = process.env.REACT_APP_IMG_URL + item["cover_image"]));

      setProjects(result);
    } catch (e) {
      console.log(e);
    }
  };

  const goToImages = (id) => {
    navigate("/projects/" + id);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Teszveszgarden</title>
        <meta name="description" content="Kerti projekteink" />
        <link rel="canonical" href="https://teszveszgarden.ro/projects" />
        <meta
          name="keywords"
          content="Kerteszet, kertepites, teruletgondozas, gyepnyiras, fu nyiras, Marefalva, Szekelyudvarhely"
        />
      </Helmet>

      <div className="projects__container">
        {windowWidth < 900 ? (
          <>
            <MobielNavBar />
          </>
        ) : (
          <div className="landing-section__navbar">
            <NavBar />
          </div>
        )}

        <div className="u-center-text u-margin-bottom-medium">
          <h2 className="heading-secondary heading-secondary--white">
            {t("project_title")}
          </h2>
        </div>

        <Grid
          container
          direction={gridDirection}
          rowGap={5}
          columnGap={5}
          justifyContent="center"
          alignItems="center"
        >
          {projects !== undefined &&
            projects.map((elem, index) => (
              <Grid item xs={3} key={index} className="pgrid-item">
                <div className="pcard">
                  <div className="pcard__side pcard__side--front">
                    <img
                      alt="card"
                      className="pcard__picture"
                      src={elem["cover_image"]}
                    />
                    <h4 className="pcard__heading">
                      <span className="pcard__heading-span pcard__heading-span--1">
                        {i18n.language === "hu"
                          ? elem["title"]
                          : elem["title_ro"]}
                      </span>
                    </h4>
                    <div className="pcard__details">
                      <ul>
                        {i18n.language === "hu"
                          ? elem["details"].map((item, index) => (
                              <li key={index}>{item}</li>
                            ))
                          : elem["details_ro"].map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                      </ul>
                    </div>
                  </div>
                  <div className="pcard__side pcard__side--back pcard__side--back-1">
                    <div className="pcard__cta">
                      <div className="pcard__price-box">
                        <button
                          onClick={() => goToImages(elem["id"])}
                          className="btn btn--green"
                        >
                          {t("project_more")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
        <br/>
        <br/>
        <br/>
        <Footer/>
      </div>
    </React.Fragment>
  );
};

export default Projects;
