import axiosInstance from "../axiosApi";


const postMessage = (data) =>{
    return axiosInstance.post('/tszvmessage/post_message/',data);
}

const getVisitorsMessage = () =>{
    return axiosInstance.get('/tszvmessage/all_message/');
}

export default {
    postMessage,
    getVisitorsMessage,
}